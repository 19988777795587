import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";

import {m_domain, clientID, oktaAudience, environment} from "./data"

import { createRoot } from 'react-dom/client';
import App from "./App"

if (environment === 'prod') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Auth0Provider
  domain={m_domain}
  clientId={clientID}
  authorizationParams={{
    audience: oktaAudience,
    redirect_uri: window.location.origin,
    scope: "profile email openid"
  }}
  useRefreshTokens={true}
  cacheLocation={'localstorage'}
>
  <App />
</Auth0Provider>)