import { Form, Button, Input, Modal } from "antd";
import { useState } from "react";
import { dataEndpoint } from "../data";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { get_docs_url } from "../helpers";

const call_register_user_api = async (values) => {
  try {
    console.log(dataEndpoint)
    const requestOptions = {
      method: 'POST',
      headers: { "Authorization": values.aws_token },
      body: JSON.stringify({
        aws_token: values.aws_token,
        user_email: values.user_email,
        org_name: values.org_name
      })
    }
    console.log(requestOptions)
    const getUserData = `${dataEndpoint}/api/register/awsaccount`;
    const resp = await fetch(getUserData, requestOptions)
    console.log(resp)
    const data = await resp.json()
    console.log(data)
    const status = resp.status
    return status
  } catch (error) {
    console.log(error)
    return 699
  }
  
}

async function register_user(values, setFlowState, setUserEmail, retry=0) {
  console.log("retry", retry)
  console.log("values", values)

  function x() {
    return new Promise((resolve, reject) => {
      var status = call_register_user_api(values)
      resolve(status)
    });
  }
  setUserEmail(values.user_email)
  const result = await x();
  if (result === 200) {
    console.log('succeessful register')
    setFlowState("success")
  } else if (retry < 3) {
    console.log('retrying the request again')
    register_user(values, setFlowState, retry + 1)
  } else {
    console.log('received a failure attempting to make the request 3 times')
    setFlowState("failure")
  }
}

const formSubmit = (values, setSubmitState, setFlowState, setUserEmail) => {
  setSubmitState(true)
  setFlowState("submitting")
  console.log("formSubmit", values)
  register_user(values, setFlowState, setUserEmail)
}

const SingupData = (props, flowState, setFlowState) => {
  const [submitState, setSubmitState] = useState(false)
  const [userEmail, setUserEmail] = useState(null)
  if (flowState === "landing") {
    return (
      <Form
        labelCol={{span: 10}}
        wrapperCol={{span: 16}}
        disabled={submitState}
        initialValues={{aws_token: props.registree_data}}
        // style={{
        //   maxWidth: 850,
        // }}
        onFinish={(values) => {return formSubmit(values, setSubmitState, setFlowState, setUserEmail)}}
      >
        <Form.Item 
          label={<>Email<QuestionCircleOutlined style={{marginLeft: 5}} onClick={() => {get_docs_url("/registration/#register-in-the-saas")}}/></>}
          name="user_email"
          required={true}
          rules={[
            {
              required: true,
            },
            {
              type: 'email',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item 
          label={<>New Organization Name<QuestionCircleOutlined style={{marginLeft: 5}} onClick={() => {get_docs_url("/registration/#register-in-the-saas")}}/></>}
          name="org_name"
          required={true} 
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item 
          label="AWS Token" 
          name="aws_token"
          hidden={true}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="submitbutton"
        >
          <Button 
            type="primary" 
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
        <Form.Item
          hidden={!submitState}
          wrapperCol={{offset: 8}}
        >
          Creating new subscription.
        </Form.Item>
      </Form>
    )
  }
  if (flowState === "failure") {
    return (<div>
      <h1>Unexpected failure</h1>
      <h2>Please save the following value</h2>
      <p>{props.registree_data}</p>
      <p>please reach out to support@skysaver.io with your registree data and the date/time this registration was attempted</p>
    </div>)
  }
  if (flowState === "submitting") {
    return (<div>
      Submitting
    </div>)
  }
  if (flowState === "success") {
    return (<div>
      <h1>Success!</h1>
      <p>Please login with {userEmail} to begin using the service!</p>
    </div>)
  }
}

const closeModal = (setOpen) => {
  setOpen(false)
  window.location.href = "/"
}

const generateModalFooter = (flowState, setOpen) => {
  if (flowState === "success") {
    return [
      <Button key="back" onClick={() => {closeModal(setOpen)}}>
        Finish
      </Button>
    ]
  } else if (flowState === "failure") {
    return [
      <Button key="back" onClick={() => {closeModal(setOpen)}}>
        Finish
      </Button>
    ]
  } else {
    return []
  }
}

const SignupFlow = (props) => {
  const [flowState, setFlowState] = useState("landing")
  const [open, setOpen] = useState(true)

  
  return (
    <Modal 
      open={open}
      footer={generateModalFooter(flowState, setOpen)}
    >
      {SingupData(props, flowState, setFlowState)}
    </Modal>
  )
}

export default SignupFlow;