import { useState } from "react"
import { Button } from "antd"
import ModifyAccount from "./ModifyAccount"
import {checkUserAccountConfigured} from "../requests"


const AccountsViewActions = (props) => {
  const [configureClicked, setConfiugreClicked] = useState(false);
  console.log(configureClicked)
  console.log("AccountsViewActions")

  return <div>
    <Button onClick={() => {props.modal.confirm({ title: "Update Account", footer: [], content: (<ModifyAccount account={props.account} common={props.common} setAccountsFunc={props.setAccountsFunc}/>)})}}>Edit</Button>
    {props.account.configured || configureClicked ? <></> : <Button hidden={configureClicked} onClick={() => { 
      console.log("Check clicked"); 
      setConfiugreClicked(true);
      checkUserAccountConfigured(
        props.common.api_token,
        props.common.oauth_id_token.__raw,
        props.account.accountid,
        props.common.project.selectedProject.id
      )}}>Check</Button>}
  </div>
}

export default AccountsViewActions;