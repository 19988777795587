// getProjectDetails
import { useState } from "react";
import {getProjectDefaults, updateOrgDefaults} from "../requests";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Row, Divider, message, Input, Form, Switch, Button, Select, Popover } from "antd";

const ProjectDefaults = (props) => {
  const [projectData, setProjectData] = useState(null)
  const [messageApi, contextHolder] = message.useMessage();
  if(projectData == null) {
    getProjectDefaults(props.common.project.selectedProject.id, props.common.api_token, props.common.oauth_id_token.__raw).then((data) => {
      setProjectData(data)
    }).then(() => {
      messageApi.destroy()
    })
  }
  if(projectData == null) {
    setTimeout(() => {
      messageApi.loading('Loading project data');
    }, 10)
    return <div>{contextHolder}</div>
  }
  console.log("projectData", projectData)
  console.log("props.common", props.common)
  console.log(props.common.project)
  const canModify = props.common.scopes.scopes.scopes.includes("manage:project")
  const project_name = props.common.project.selectedProject.name
  const dd = {
    ...projectData
  }

  const region_options = [
    {label: "us-east-1", value: "us-east-1"},
    {label: "us-east-2", value: "us-east-2"},
    {label: "us-west-1", value: "us-west-1"},
    {label: "us-west-2", value: "us-west-2"},
    {label: "ca-central-1", value: "ca-central-1"},
    {label: "eu-central-1", value: "eu-central-1"},
    {label: "eu-west-1", value: "eu-west-1"},
    {label: "eu-west-2", value: "eu-west-2"},
    {label: "eu-west-3", value: "eu-west-3"},
    {label: "eu-north-1", value: "eu-north-1"},
    {label: "sa-east-1", value: "sa-east-1"},
    {label: "ap-south-1", value: "ap-south-1"},
    {label: "ap-northeast-1", value: "ap-northeast-1"},
    {label: "ap-northeast-2", value: "ap-northeast-2"},
    {label: "ap-northeast-3", value: "ap-northeast-3"},
    {label: "ap-southeast-1", value: "ap-southeast-1"},
    {label: "ap-southeast-2", value: "ap-southeast-2"}
  ]

  const submitProjectUpdate = (data) => {
    console.log("updated data", data)
    messageApi.loading("Submitting Update", 5)
    updateOrgDefaults(data, props.common.api_token, props.common.project.selectedProject.id, props.common.oauth_id_token.__raw).then(
      (response) => {
        console.log(response)
        messageApi.destroy()
        messageApi.success("", 4)
        setProjectData(data)
      }
    ).catch((reason) => {
      console.log(reason)
      messageApi.destroy()
      messageApi.error("Failure Submitting Project Update", 10)
    })
  }

  console.log("dd", dd)
  return (
    <div style={{margin: '8px'}}>
      {contextHolder}
      <Row justify="center">
        <Col span={24} style={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '30px',
        }}>
          {project_name}
        </Col>
      </Row>
      <Divider></Divider>
      <Form
        initialValues={dd}
        onFinish={submitProjectUpdate}>
        <Row>
          <Col span={8}>
            <Form.Item
              label="Default Tag"
              name="schedule_tag"
              style={{marginLeft: "4px", marginRight: "4px"}}>
              <Input disabled={!!!canModify}/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Default Role Name"
              name="xa_role_name"
              style={{marginLeft: "4px", marginRight: "4px"}}>
              <Input disabled={!!!canModify}/>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="EC2 Scheduling Enabled"
          name="ec2_enabled"
          valuePropName="checked">
          <Switch checkedChildren="Yes" unCheckedChildren="No" disabled={!!!canModify}/>
        </Form.Item>
        <Form.Item
          label="Lambda Scheduling Enabled"
          name="lambda_enabled"
          valuePropName="checked">
          <Switch checkedChildren="Yes" unCheckedChildren="No" disabled={!!!canModify}/>
        </Form.Item>
        <Form.Item
          label="RDS Scheduling Enabled"
          name="rds_enabled"
          valuePropName="checked">
          <Switch checkedChildren="Yes" unCheckedChildren="No" disabled={!!!canModify}/>
        </Form.Item>
        <Form.Item
          label="Autoscaling Scheduling Enabled"
          name="asg_enabled"
          valuePropName="checked">
          <Switch checkedChildren="Yes" unCheckedChildren="No" disabled={!!!canModify}/>
        </Form.Item>
        <Form.Item
          name="project_id"
          hidden={true}>
          <Input disabled={!!!canModify}/>
        </Form.Item>
        <Form.Item
          name="project_name"
          hidden={true}>
          <Input disabled={!!!canModify}/>
        </Form.Item>
        <Form.Item
          label={<div>Active Regions <Popover content={"This is a global value and cannot be modified at the account level"} ><QuestionCircleOutlined /></Popover></div>}
          name="regions">
          <Select 
            options={region_options}
            mode="multiple"
            disabled={!!!canModify}>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={!!!canModify}>
            {"Update"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default ProjectDefaults;