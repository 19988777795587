import { Form, Space, Input, Select, TimePicker, Card, Divider } from "antd";
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import ResourcePool from "./ResourcePool";
import { get_docs_url } from "../helpers";

const GenericPeriod = (props) => {
  console.log("GenericPeriod", props)
  return <>
    <Form.Item
      label={<div>Start <QuestionCircleOutlined onClick={() => {get_docs_url("/schedules/periods/#start")}}/></div>}
      style={{margin: 2}}
      name={[props.data.name, 'start_time']}>
      <TimePicker format={"HH:mm"} minuteStep={1} hourStep={1}/>
    </Form.Item>
    <Form.Item
      label={<div>End <QuestionCircleOutlined onClick={() => {get_docs_url("/schedules/periods/#end")}}/></div>}
      style={{margin: 2}}
      name={[props.data.name, 'end_time']}>
      <TimePicker format={"HH:mm"} minuteStep={1} hourStep={1}/>
    </Form.Item>
    <Form.Item
      label={<div>Days <QuestionCircleOutlined onClick={() => {get_docs_url("/schedules/periods/#days")}}/></div>}
      style={{margin: 2, width: 500}}
      name={[props.data.name, 'weekdays']}>
      <Select 
        options={[{ "value": "mon", "label": "mon" }, { "value": "tue", "label": "tue" }, { "value": "wed", "label": "wed" }, { "value": "thu", "label": "thu" }, { "value": "fri", "label": "fri" }, { "value": "sat", "label": "sat" }, { "value": "sun", "label": "sun" }]}
        mode="multiple">
      </Select>
    </Form.Item>
  </>
}

const ASGPeriod = (props) => {
  return (
    <Card 
      size="small"
      key={props.data.key} 
      title={`Period ${props.data.name + 1}`}
      style={{marginBottom: "1%", gap: "2px 2px"}}
      extra={
        <CloseOutlined
          onClick={() => {
            props.remove(props.data.name);
          }}
        />
      }
    >
      <Space size={[2, 2]} wrap>
        {GenericPeriod(props)}
        <ResourcePool name={props.data.name} top_level_name="resource_pool" setup={true}/>
      </Space>
    </Card>
  )
}

const EC2Period = (props) => {
  return (
  <Card 
    size="small"
    key={props.data.key} 
    title={`Period ${props.data.name + 1}`}
    style={{marginBottom: "1%"}}
    extra={
      <CloseOutlined
        onClick={() => {
          props.remove(props.data.name);
        }}
      />
    }
  >
    <Space size={[2, 2]} wrap>
      {GenericPeriod(props)}
    </Space>
    <Divider style={{fontSize: 10}}>Instance Type</Divider>
    <Form.Item
      label={<div>Instance Type <QuestionCircleOutlined onClick={() => {get_docs_url("/schedules/periods/ec2/#instance-type")}}/></div>}
      name={[props.data.name, 'instance_type']}
      style={{margin: 2, width:250}}>
      <Input />
    </Form.Item>
  </Card>)
}

const LambdaPeriod = (props) => {
  return (
    <Card 
      size="small"
      key={props.data.key} 
      title={`Period ${props.data.name + 1}`}
      style={{marginBottom: "1%"}}
      extra={
        <CloseOutlined
          onClick={() => {
            props.remove(props.data.name);
          }}
        />
      }
    >
      <Space size={[3, 3]} wrap>
        {GenericPeriod(props)}
      </Space>
      <Divider style={{fontSize: 10}}>Concurrency</Divider>
      <Space size={[2,2]} wrap>
        <Form.Item
          label={<div>Reserved Concurrency <QuestionCircleOutlined onClick={() => {get_docs_url("/schedules/periods/lambda/#reserved-concurrency")}}/></div>}
          style={{margin: 2}}
          name={[props.data.name, 'reserved_concurrency']}>
          <Input/>
        </Form.Item>
        <Form.Item
          label={<div>Provisioned Concurrency <QuestionCircleOutlined onClick={() => {get_docs_url("/schedules/periods/lambda/#provisioned-concurrency")}}/></div>}
          style={{margin: 2}}
          name={[props.data.name, 'provisioned_concurrency']}>
          <Input/>
        </Form.Item>
      </Space>
    </Card>)
}

const RDSPeriod = (props) => {
  console.log("RDSPeriod", props)
  return (
    <Card 
      size="small"
      key={props.data.key} 
      title={`Period ${props.data.name + 1}`}
      style={{marginBottom: "1%"}}
      extra={
        <CloseOutlined
          onClick={() => {
            props.remove(props.data.name);
          }}
        />
      }
    >
      <Space size={[2, 2]} wrap>
        {GenericPeriod(props)}
      </Space>
      <Divider style={{fontSize: 10}}>Instance Based</Divider>
      <Form.Item
        label={<div>Instance Type <QuestionCircleOutlined onClick={() => {get_docs_url("/schedules/periods/rds/#instance-type")}}/></div>}
        style={{margin: 2}}
        name={[props.data.name, 'instance_type']}>
        <Input/>
      </Form.Item>
      <Divider style={{fontSize: 10}}>Serverless</Divider>
      <Space size={[2,2]} wrap>
        <Form.Item
          label={<div>Min ACU <QuestionCircleOutlined onClick={() => {get_docs_url("/schedules/periods/rds/#min-acu")}}/></div>}
          style={{margin: 2}}
          name={[props.data.name, 'rds_serverless_config', 'min']}>
          <Input/>
        </Form.Item>
        <Form.Item
          label={<div>Max ACU <QuestionCircleOutlined onClick={() => {get_docs_url("/schedules/periods/rds/#max-acu")}}/></div>}
          style={{margin: 2}}
          name={[props.data.name, 'rds_serverless_config', 'max']}>
          <Input/>
        </Form.Item>
      </Space>
    </Card>)
}

const ECSPeriod = (props) => {
  return (
    <Card 
      size="small"
      key={props.data.key} 
      title={`Period ${props.data.name + 1}`}
      style={{marginBottom: "1%"}}
      extra={
        <CloseOutlined
          onClick={() => {
            props.remove(props.data.name);
          }}
        />
      }
    >
      <Space size={[2, 2]} wrap>
        {GenericPeriod(props)}
      </Space>
    </Card>)
}

const Period = (props) => {
  console.log("Period.props", props)
  if(props.type === "ec2") {
    return <EC2Period data={props.data} remove={props.remove} />
  }
  if(props.type === "lambda") {
    return <LambdaPeriod data={props.data} remove={props.remove} />
  }
  if(props.type === "asg") {
    return <ASGPeriod data={props.data} remove={props.remove} />
  }
  if(props.type === "rds") {
    return <RDSPeriod data={props.data} remove={props.remove} />
  }
  if(props.type === "ecs") {
    return <ECSPeriod data={props.data} remove={props.remove} />
  }
  return <div>Unknown Period</div>
}

export default Period;