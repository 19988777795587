import { Button, Col, Divider, Input, Modal, Row, Table, message, Tag } from "antd"
import { useState } from "react"
import { RedoOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import {deleteAccountsFromOrg} from "../requests"
import AccountsViewActions from "./AccountsViewActions"

// async function performGlobalServiceAction(user, token, project, service, action) {
//   console.log(user, token, project, service, action)
// }

// const generateEnableDropdownItems = (props, action) => {
//   return [
//     {
//       key: "1",
//       label: (<div>ec2</div>),
//       onClick: () => {performGlobalServiceAction(props.common.user, props.common.api_token, props.common.project.selectedProject.id, "ec2", action)}
//     },
//     {
//       key: "2",
//       label: (<div>rds</div>),
//       onClick: () => {performGlobalServiceAction(props.common.user, props.common.api_token, props.common.project.selectedProject.id, "rds", action)}
//     },
//     {
//       key: "3",
//       label: (<div>lambda</div>),
//       onClick: () => {performGlobalServiceAction(props.common.user, props.common.api_token, props.common.project.selectedProject.id, "lambda", action)}
//     },
//     {
//       key: "4",
//       label: (<div>autoscaling</div>),
//       onClick: () => {performGlobalServiceAction(props.common.user, props.common.api_token, props.common.project.selectedProject.id, "autoscaling", action)}
//     }
//   ]
// }

const columns = [
  {
    title: 'Account ID',
    dataIndex: 'accountId',
  },
  {
    title: 'Configured',
    dataIndex: 'configured',
  },
  {
    title: 'Features',
    dataIndex: 'features',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
  }
]

const generateTags = (accountData) => {
  var res = []
  if(accountData.ec2_enabled) {
    res.push(<Tag key={1}>EC2</Tag>)
  }
  if(accountData.rds_enabled) {
    res.push(<Tag key={2}>RDS</Tag>)
  }
  if(accountData.lambda_enabled) {
    res.push(<Tag key={3}>Lambda</Tag>)
  }
  if(accountData.asg_enabled) {
    res.push(<Tag key={4}>Autoscaling Groups</Tag>)
  }
  return res 
}

const generateRows = (accounts, filter, modal, props, setAccountsFunc) => {
  if (accounts) {
    const nAccounts = accounts.filter((account) => {
      if(filter === "" || filter === null) {
        return true
      } else {
        if(account.accountid.includes(filter)) {
          return true
        }
        return false
      }
    })
    let res = nAccounts.map((account) => {
      console.log("generateRows.account", account)
      return {
        key: account.accountid,
        accountId: account.accountid,
        configured: (account.configured ? <CheckOutlined /> : <CloseOutlined />),
        features: generateTags(account),
        actions: <AccountsViewActions common={props.common} account={account} setAccountsFunc={setAccountsFunc} modal={modal}/>
      }
    })
    return res
  } else {
    return []
  }
}

const AccountsView = (props) => {
  const [modal, modalContext] = Modal.useModal()
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  console.log("AccountsView")
  console.log(modal)
  const [filter, setFilter] = useState("")
  console.log(props)
  if(props.common.project.selectedProject === null) {
    return (<div>
      Please select an Organization
    </div>)
  }
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const deleteAccounts = () => {
    messageApi.open({
      type: "loading",
      content: "removing accounts",
      duration: 0
    })
    console.log(selectedRowKeys)
    deleteAccountsFromOrg(selectedRowKeys, props.common.api_token, props.common.project.selectedProject.id, props.common.oauth_id_token.__raw).then(
      (res_code) => {
        console.log("res_code for deleteAccounts", res_code)
        messageApi.destroy()
        messageApi.open({
          type: "success",
          content: "successfully removed the selected accounts",
          duration: 0
        })
        setTimeout(()=> {messageApi.destroy(); props.setAccountsFunc(null)}, 3000)
      }
    )
  }
  const row = generateRows(props.accounts, filter, modal, props, props.setAccountsFunc)
  // const enableDDitems = generateEnableDropdownItems(props, "enable")
  // const disableDDitems = generateEnableDropdownItems(props, "disable")
  // console.log(enableDDitems)
  if(props.accounts != null) {
    return (<div>
      <Divider style={{height:"5vh"}}>Global Actions</Divider>
      {modalContext}
      <Row style={{height:"10vh", overflow: "hidden"}}>
        {/* <Col span="2" offset="1">
          <Dropdown menu={{items: enableDDitems}}><Button>Enable</Button></Dropdown>
        </Col>
        <Col span="2" offset="1">
          <Dropdown menu={{items: disableDDitems}}><Button>Disable</Button></Dropdown>
        </Col> */}
        <Col span="4" offset="13">
          {contextHolder}
          <Button style={{margin: 8}} icon={<CloseOutlined />} type="primary" onClick={deleteAccounts}>Remove Selected</Button>
        </Col>
        <Col span="2">
          <Input style={{margin: 8}} placeholder="account" onChange={(e) => {console.log(e); setFilter(e.target.value)}} />
        </Col>
        <Col span="2" offset="1">
          <Button style={{margin: 8}} icon={<RedoOutlined />} type="primary" onClick={() => {props.setAccountsFunc(null)}}>Reload</Button>
        </Col>
      </Row>
      <Divider style={{height:"5vh"}}>Accounts</Divider>
      <Row style={{height:"60vh", overflow: "scroll"}}>
        <Col span="24">
          <Table 
            size="small"
            virtual
            style={{marginLeft: 8, marginBottom: 16}} 
            pagination={{ position: ["topRight", "bottomRight"] }}
            rowSelection={rowSelection} 
            columns={columns} 
            dataSource={row}
            />
          </Col>
        </Row>
    </div>)
  } else {
    setTimeout(() => {
      messageApi.loading("loading accounts", 5);
    }, 5)
    return (<div>
      {contextHolder}
    </div>)
  }
}

export default AccountsView;