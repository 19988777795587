import { Button, Form, Modal, Input, Checkbox } from "antd";
import { useState } from "react";
import {updateAccountForOrg} from "../requests";

const ModifyAccount = (props) => {
  const [makingRequest, updateRequest] = useState(false)
  const oldData = props.account
  console.log("ModifyAccount", props)
  const submitUpdate = (data) => {
    const it = async (nData, oldData) => {
      updateRequest(true)
      let updateData = {
        ...oldData,
        ...nData
      }
      console.log("oldData", oldData)
      console.log("nData", nData)
      console.log("submitting the update", updateData)
      const resCode = await updateAccountForOrg(updateData, props.common.api_token, props.common.project.selectedProject.id, props.common.oauth_id_token.__raw)
      console.log("submitUpdate.it.resCode", resCode)
      return resCode
    }
    it(data, oldData).then((res) => {
      console.log(res)
      updateRequest(false)
      Modal.destroyAll()
      props.setAccountsFunc(null)
    })
  }
  return (
    <Form
      initialValues={props.account}
      onFinish={submitUpdate}
      disabled={makingRequest}>
      <Form.Item
        label="Account ID"
        name="accountid">
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Role Name"
        name="xa_role_name"
        >
        <Input />
      </Form.Item>
      <Form.Item
        label="Tag"
        name="schedule_tag"
        >
        <Input />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        label="EC2 Scheduled"
        name="ec2_enabled"
        >
        <Checkbox />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        label="Lambda Scheduled"
        name="lambda_enabled"
        >
        <Checkbox />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        label="RDS Scheduled"
        name="rds_enabled"
        >
        <Checkbox />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        label="ASG Scheduled"
        name="asg_enabled"
        >
        <Checkbox />
      </Form.Item>
      <Button onClick={Modal.destroyAll} disabled={makingRequest}>Cancel</Button>
      <Button type="primary" htmlType="submit" loading={makingRequest}>Update</Button>
    </Form>
  )
}

export default ModifyAccount;