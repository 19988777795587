import { TreeSelect } from 'antd';

async function resetUserScopes(func) {
  func(null)
}

const Project = (props) => {
  console.log("Project props", props.common)

  var options = []
  if (props.common.project.userProjects) {
    options = props.common.project.userProjects.projects.map((project) => { 
      return { value: project.id, title: project.name, key: project.id } 
    })
  }

  const handleProjectSelection = (newProject) => {
    console.log("newProject: ", newProject);
    resetUserScopes(props.common.scopes.setUserScopesFunc)
    props.common.project.setSelectedProjectFunc(newProject);
  }

  if (options.length > 0) {
    if(!!props.common.project.selectedProject) {
      return (<TreeSelect value={props.common.project.selectedProjectId} style={{ width: "100%" }} onChange={handleProjectSelection} treeData={options} />)
    } else {
      return (<TreeSelect placeholder="select project" style={{ width: "100%" }} onChange={handleProjectSelection} treeData={options} />)
    }
  } else {
    return (<div></div>)
  }
}

export default Project;