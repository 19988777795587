import { Divider, Form, Input, Space } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { get_docs_url } from "../helpers";

const ResourcePool = (props) => {
  console.log(props.name != null ? ["periods", props.name, props.top_level_name, "desired"] : [props.top_level_name, "desired"])
  console.log(props.name != null ? ["periods", props.name, props.top_level_name, "max"] : [props.top_level_name, "max"])
  console.log(props.name != null ? ["periods", props.name, props.top_level_name, "min"] : [props.top_level_name, "min"])
  return <>
    <Divider style={{fontSize: 10}}>{props.default ? "Default" : ""} Resource Pool</Divider>
    <Space size={[2, 2]} wrap>
      <Form.Item
        label={props.setup && props.setup === true ? <div>Desired <QuestionCircleOutlined onClick={() => {get_docs_url("/schedules/periods/autoscaling-groups/#desired")}}/></div> : "Desired"}
        name={props.name != null ? [props.name, props.top_level_name, "desired"] : [props.top_level_name, "desired"]}
        style={{marginBottom: 0}}
        rules={[
          {
            required: true,
            message: 'Must specify the desired autoscaling count',
          },
        ]}>
        <Input />
      </Form.Item>
      <Form.Item
        label={props.setup && props.setup === true ? <div>Max <QuestionCircleOutlined onClick={() => {get_docs_url("/schedules/periods/autoscaling-groups/#max")}}/></div> : "Max"}
        name={props.name != null ? [props.name, props.top_level_name, "max"] : [props.top_level_name, "max"]}
        style={{marginBottom: 0}}
        rules={[
          {
            required: true,
            message: 'Must specify the max autoscaling count',
          },
        ]}>
        <Input />
      </Form.Item>
      <Form.Item
        label={props.setup && props.setup === true ? <div>Min <QuestionCircleOutlined onClick={() => {get_docs_url("/schedules/periods/autoscaling-groups/#min")}}/></div> : "Min"}
        name={props.name != null ? [props.name, props.top_level_name, "min"] : [props.top_level_name, "min"]}
        style={{marginBottom: 0}}
        rules={[
          {
            required: true,
            message: 'Must specify the min autoscaling count',
          },
        ]}>
        <Input />
      </Form.Item>
    </Space>
  </>
};

export default ResourcePool;