import { Button, Col, Divider, Modal, Row, Table, Tag, Space } from "antd"
import { useState } from "react"
import {removeUser} from "../requests"
import ModifyMember from "./ModifyMember"

async function performGlobalServiceAction(user, token, project, service, action) {
  console.log(user, token, project, service, action)
}

const generateEnableDropdownItems = (props, action) => {
  return [
    {
      key: "1",
      label: (<div>ec2</div>),
      onClick: () => {performGlobalServiceAction(props.common.user, props.common.api_token, props.common.project.selectedProject.id, "ec2", action)}
    },
    {
      key: "2",
      label: (<div>rds</div>),
      onClick: () => {performGlobalServiceAction(props.common.user, props.common.api_token, props.common.project.selectedProject.id, "rds", action)}
    },
    {
      key: "3",
      label: (<div>lambda</div>),
      onClick: () => {performGlobalServiceAction(props.common.user, props.common.api_token, props.common.project.selectedProject.id, "lambda", action)}
    }
  ]
}

const columns = [
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Features',
    dataIndex: 'features',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
  }
]

const generateTags = (permissions) => {
  console.log("MembersView.generateTags.permissions", permissions)
  var res = []
  res = permissions.map((perm, i) => { return <Tag key={i}>{perm}</Tag> })
  console.log("generated " + res.length + " tags")
  return res 
}

const generateRows = (members, filter, modal, props, setMembersFunc) => {
  console.log("MembersView.generateRows.members", members)
  if (members) {
    const nMembers = members.filter((member) => {
      if(filter === "" || filter === null) {
        return true
      } else {
        if(member.email.includes(filter)) {
          return true
        }
        return false
      }
    })
    const deleteHandler = (email) => {
      let nMembers = members.filter((member) => { return member.email !== email})
      removeUser(email, props.common.api_token, props.common.project.selectedProject.id, props.common.oauth_id_token.__raw)
      setMembersFunc(nMembers)
    }
    let res = nMembers.map((member, i) => {
      console.log("generateRows.member", member)
      return {
        key: i,
        email: member.email,
        features: generateTags(member.permissions),
        actions: (<Space>
          { props.common.user.email === member.email ? null : <Button onClick={() => {modal.confirm({ title: "Update Member Permissions", footer: [], content: (<ModifyMember member={member} common={props.common} setMembersFunc={setMembersFunc}/>)})}}>Edit</Button>}
          { props.common.user.email === member.email ? null : <Button onClick={() => {deleteHandler(member.email)}}>Delete</Button> }
        </Space>)
      }
    })
    return res
  } else {
    return []
  }
}

const AccountsView = (props) => {
  const [modal, modalContext] = Modal.useModal()
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  console.log("AccountsView")
  console.log(modal)
  const [filter] = useState("")
  console.log(props)
  if(props.common.project.selectedProject === null) {
    return (<div>
      Please select an Organization
    </div>)
  }
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const row = generateRows(props.members, filter, modal, props, props.setMembersFunc)
  const enableDDitems = generateEnableDropdownItems(props, "enable")
  console.log(enableDDitems)
  if(props.members != null) {
    return (<div>
      {modalContext}
      <Divider style={{height:"5vh"}}>Project Members</Divider>
      <Row style={{height:"75vh", overflow: "scroll"}}>
        <Col span="24">
          <Table 
            size="small"
            virtual
            style={{marginLeft: 8, marginBottom: 16}} 
            pagination={{ position: ["topRight", "bottomRight"] }}
            rowSelection={rowSelection} 
            columns={columns} 
            dataSource={row}
            />
          </Col>
        </Row>
    </div>)
  } else {
    return (<div>
      Loading...
    </div>)
  }
}

export default AccountsView;