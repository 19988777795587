import {retrieveAccounts} from "../requests"
import { useState } from "react"
import AccountsView from "../components/AccountsView"


const AccountsData = (props) => {
  const [accounts, setAccounts] = useState(null)

  console.log("accounts", accounts)
  console.log("accounts === null", accounts === null)
  if(accounts === null) {
    retrieveAccounts(setAccounts, props.common.api_token, props.common.project.selectedProject.id, props.common.oauth_id_token.__raw)
  }

  console.log("generating the AccountsView")
  return (<AccountsView accounts={accounts} setAccountsFunc={setAccounts} common={props.common}/>)
}

export default AccountsData;