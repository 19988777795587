import { Button, Form, Modal, Input, Select, Tag, Divider } from "antd";
import { useState } from "react";
import {updateUserForOrg} from "../requests";

const scopes = [
  {value: "write:schedules"}, {value: "manage:schedules"}, {value: "read:schedules"}, {value: "read:accounts"},
  {value: "write:accounts"}, {value: "manage:account"}, {value: "delete:accounts"},
  {value: "write:project_members"}, {value: "delete:project_members"}, {value: "manage:project_members"},
  {value: "read:project_members"}, {value: "manage:project"}, {value: "read:project"}
]

const tagRender = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={"success"}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
      }}
    >
      {label}
    </Tag>
  );
};

const ModifyMember = (props) => {
  const [makingRequest, updateRequest] = useState(false)
  const oldData = props.member
  const [form] = Form.useForm();
  console.log("ModifyAccount", props)
  const submitUpdate = (data) => {
    const it = async (nData, oldData) => {
      updateRequest(true)
      let updateData = {
        ...oldData,
        ...nData
      }
      console.log("oldData", oldData)
      console.log("nData", nData)
      try {
        console.log("submitting the update", updateData)
        const resCode = await updateUserForOrg(updateData, props.common.api_token, props.common.project.selectedProject.id, props.common.oauth_id_token.__raw)
        console.log("submitUpdate.it.resCode", resCode)
        return resCode
      } catch (exceptionVar) {
        console.log(exceptionVar)
        return 403
      }
    }
    const cleanup = () => {
      Modal.destroyAll()
      props.setMembersFunc(null)
    }
    it(data, oldData).then((res) => {
      console.log(res)
      var wait = 1500
      updateRequest(false)
      if (res === 200) {
        Modal.info({ title: "Updated Member Permissions" })
      } else {
        Modal.error({ title: "Failure updating the user" })
        wait = 5000
      }
      setTimeout(cleanup, wait)
    })
  }
  const handleSelectAllClick = () => {
    console.log("handleSelectAllClick")
    form.setFieldValue(
      "permissions",
      scopes.map(({value}) => value)
    );
    form.validateFields(["permissions"]);
  }
  const handleRemoveAllClick = () => {
    console.log("handleRemoveAllClick")
    form.setFieldValue(
      "permissions",
      []
    );
    form.validateFields(["permissions"]);
  }
  return (
    <Form
      form={form}
      initialValues={props.member}
      onFinish={submitUpdate}
      disabled={makingRequest}>
      <Form.Item
        label="Account ID"
        name="email">
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Scopes"
        name="permissions">
        <Select 
          mode="multiple"
          tagRender={tagRender}
          options={scopes}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider />
              <div>
                <Button
                  type="text"
                  className="w-100"
                  shape="round"
                  onClick={handleSelectAllClick}
                >
                  Give Admin Permissions
                </Button>
              </div>
              <div>
                <Button
                  type="text"
                  className="w-100"
                  shape="round"
                  onClick={handleRemoveAllClick}
                >
                  Remove All Permissions
                </Button>
              </div>
            </>
          )}>
        </Select>
      </Form.Item>
      <Button onClick={Modal.destroyAll} disabled={makingRequest}>Cancel</Button>
      <Button type="primary" htmlType="submit" loading={makingRequest}>Update</Button>
    </Form>
  )
}

export default ModifyMember;