import MembersView from "../components/MembersView"
import { useState } from "react"
import { retrieveMembers } from "../requests"

const MembersData = (props) => {
  const [members, setMembers] = useState(null)

  console.log("members", members)
  console.log("members === null", members === null)
  if(members === null) {
    retrieveMembers(setMembers, props.common.api_token, props.common.project.selectedProject.id, props.common.oauth_id_token.__raw)
  }

  console.log("generating the MembersView")
  return (<MembersView members={members} setMembersFunc={setMembers} common={props.common}/>)
}

export default MembersData;