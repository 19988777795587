import {useSearchParams} from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from 'react';
import SignupFlow from '../flows/SignupFlow';
import { Card, Col, Row, Space, Typography, Popover } from 'antd';
import {docsEndpoint, dataEndpoint, environment} from '../data';
const { Title } = Typography;
const { Meta } = Card;

function has_new_registree_parameter(params) {
  var retData = params.get("awsToken")
  console.log("has_new_registree_parameter, retData: ", retData)
  console.log("has_new_registree_parameter, res: ", !!retData)
  return !!retData
}

function get_registree_data(params) {
  var data = params.get("awsToken")
  console.log("has_new_registree_parameter, retData: ", data)
  console.log("has_new_registree_parameter, res: ", !!data)
  return data
}

function add_sign_up_box(props) {
  if (!!!props.common.isLoading && !!!props.common.isAuthed){
    return (
      <Col span={6}>
        <Card bordered={false} hoverable={true} onClick={() => {window.open("https://aws.amazon.com/marketplace/pp/prodview-qd42dvuchjxue", '_blank').focus()}}>
          <b>AWS Marketplace Page</b>
        </Card>
      </Col>
    )
  }
  return <></>
}

function add_docs_box(props) {
  if (!!!props.common.isLoading && !!!props.common.isAuthed){
    return (
      <Col span={6} offset={1}>
        <Card bordered={false} hoverable={true} onClick={() => {window.open(docsEndpoint, '_blank').focus()}}>
          <b>Documentation</b>
        </Card>
      </Col>
    )
  }
  return (
    <Col span={6}>
      <Card bordered={false} hoverable={true} onClick={() => {window.open(docsEndpoint, '_blank').focus()}}>
        <b>Documentation</b>
      </Card>
    </Col>
  )
}

function add_home_page_box(props) {
  const prefix = environment !== "prod" ? environment + "." : ""
  console.log("setting a prefix of", prefix)
  return (
    <Col span={6} offset={1}>
      <Card bordered={false} hoverable={true} onClick={() => {window.open("http://" + prefix + "portal.skysaver.io/", '_blank').focus()}}>
        <b>Home Page</b>
      </Card>
    </Col>
  )
}

function get_grid_style(items, rows) {
  return {
    width: '100%',
    textAlign: 'center',
    padding: '4',
  }
}

function set_selected_project(selectedProjectFunc, project) {
  return () => {
    console.log(project)
    selectedProjectFunc(project.id)
  }
}

function generate_projects_grid(project_obj) {
  const gridStyle = get_grid_style(project_obj.userProjects.projects, 1)
  var ret = []
  console.log(project_obj.userProjects.projects)
  for (var i=0; i < project_obj.userProjects.projects.length; i++) {
    ret.push(<Card.Grid key={i} style={gridStyle} onClick={set_selected_project(project_obj.setSelectedProjectFunc, project_obj.userProjects.projects[i])}>
      {project_obj.userProjects.projects[i].name}
    </Card.Grid>)
  }
  return ret
}

function create_metric_cards(metrics_data) {
  var it = []
  if (metrics_data !== null) {
    metrics_data.forEach((metric_data) => {
      var desc = []
      metric_data.description.forEach((paragraph) => {
        var zz = []
        paragraph.forEach((line) => {
          zz.push(<>{line}<br/></>)
        })
        desc.push(<p>{zz}</p>)
      })
      it.push(
        <Popover content={<div>{desc}</div>}>
          <Card title={metric_data.title}>
            {metric_data.value}
          </Card>
        </Popover>
      )
    })
  }
  return it
}

function add_login_box(props, loginWithRedirect, metricsData) {
  console.log("add_login_box")
  if (!!!props.common.isLoading && !!!props.common.isAuthed){
    return (
      <Col span={8} offset={8}>
        <Card style={{height: "20vh", textAlign: "center", verticalAlign: "middle"}} bordered={false} hoverable={true} onClick={() => loginWithRedirect()}>
          <Meta title={<Title level={2}>Login</Title>} />
        </Card>
      </Col>
    )
  } else {
    console.log(props.common.project.selectedProject)
    if (props.common.project.selectedProject == null) {
      if (!!props.common.project.userProjects && props.common.project.userProjects.projects.length > 0) {
        return <Col span={24}>
          <Card title={<Title level={4}>Select a Project</Title>} style={{overflow: "scroll", height: "20vh", textAlign: "center", verticalAlign: "middle"}} bordered={false} hoverable={false}>
            {generate_projects_grid(props.common.project)}
          </Card>
        </Col>
      }
    } else {
      return <Col span={20} offset={2}>
        <Space 
          size="middle" style={{ display: 'flex', justifyContent: 'center' }} wrap
        >
          {create_metric_cards(metricsData)}
        </Space>
      </Col>
    }
  }
  return <></>
}

function simple_homepage_data(props, loginWithRedirect, metricsData) {
  if (!!!props.common.isLoading && !!!props.common.isAuthed) {
    return (
      <>
        <Row style={{height: '10vh'}}></Row>
        <Row style={{height: '20vh'}}>
          <Col span={8} offset={8}>
          {add_login_box(props, loginWithRedirect, metricsData)}
          </Col>
        </Row>
        <Row style={{height: '10vh'}}></Row>
        <Row justify={"center"}>
          {add_sign_up_box(props)}
          {add_docs_box(props)}
          {add_home_page_box(props)}
        </Row>
      </>
    )
  } else if (props.common.project.selectedProject == null){
    return (
      <>
        <Row style={{height: '10vh'}}></Row>
        <Row style={{height: '20vh'}}>
          <Col span={8} offset={8}>
          {add_login_box(props, loginWithRedirect, metricsData)}
          </Col>
        </Row>
        <Row style={{height: '10vh'}}></Row>
        <Row justify={"center"}>
          {add_sign_up_box(props)}
          {add_docs_box(props)}
          {add_home_page_box(props)}
        </Row>
      </>
    )
  } else {
    return (
      <>
        <Row style={{height: '10vh'}}></Row>
        <Row justify={"center"}>
          {add_sign_up_box(props)}
          {add_docs_box(props)}
          {add_home_page_box(props)}
        </Row>
        <Row style={{height: '10vh'}}></Row>
        <Row style={{height: '20vh'}}>
          {/* <Col> */}
          {add_login_box(props, loginWithRedirect, metricsData)}
          {/* </Col> */}
        </Row>
      </>
    )
  }
}

function project_not_setup(props) {
  var proj = props.common.project.selectedProject
  return proj != null && proj.setup_finished === false && proj.unsubscribed === false && proj.enabled
}

function display_project_still_setting_up(props) {
  return (
    <>
      <Row style={{height: "40vh"}}></Row>
      <Row style={{height: "20vh"}} justify={"center"}>
        This project is still being set up, please check back in few minutes
      </Row>
      <Row style={{height: "30vh"}}></Row>
    </>
  )
}

function project_subscription_failure(props) {
  var proj = props.common.project.selectedProject
  return proj != null && proj.setup_finished === false && proj.unsubscribed === false && proj.enabled === false
}

function display_subscription_failure(props) {
  return (
    <>
      <Row style={{height: "40vh"}}></Row>
      <Row style={{height: "20vh"}} justify={"center"}>
        The AWS subscription failed to set up
      </Row>
      <Row style={{height: "30vh"}}></Row>
    </>
  )
}

function project_unsubscribe_initiated(props) {
  var proj = props.common.project.selectedProject
  return proj != null && proj.setup_finished === false && proj.unsubscribed === true && proj.enabled === false
}

function display_unsubscribed_initiated(props) {
  return (
    <>
      <Row style={{height: "40vh"}}></Row>
      <Row style={{height: "20vh"}} justify={"center"}>
        This projects subscription has been cancelled, we're currently cleaning up.
      </Row>
      <Row style={{height: "30vh"}}></Row>
    </>
  )
}

function project_unsubscribed(props) {
  var proj = props.common.project.selectedProject
  return proj != null && proj.setup_finished === true && proj.unsubscribed === true && proj.enabled === false
}

async function retrieveMetricsData(auth_token, projectId, id_token) {
  if (auth_token != null && id_token != null && projectId != null) {
    const requestOptions = {
      method: 'GET',
      headers: { "Authorization": "Bearer " + auth_token + " Project " + projectId, "Identity": id_token },
    }
    const getSchedules = `${dataEndpoint}/api/project/${projectId}/metrics`;
    console.log("retrieveMetricsData", requestOptions, getSchedules)
    const resp = await fetch(getSchedules, requestOptions)
    console.log("retrieveMetricsData resp", resp)
    return resp.json()
  }
  return null
}

function display_unsubscribed(props) {
  return (
    <>
      <Row style={{height: "40vh"}}></Row>
      <Row style={{height: "20vh"}} justify={"center"}>
        This project has been successfully unsubscribed.
      </Row>
      <Row style={{height: "30vh"}}></Row>
    </>
  )
}

const Home = (props) => {
  const { loginWithRedirect } = useAuth0();
  const [searchParams] = useSearchParams();
  const [metricsData, setMetricsData] = useState(null)
  console.log(searchParams);
  if (props.common.isLoading) {
    console.log("still loading the user data")
    return (<div>Loading ...</div>)
  }
  if (has_new_registree_parameter(searchParams)) {
    return (<SignupFlow common={props.common} registree_data={get_registree_data(searchParams)}/>)
  }
  if(project_not_setup(props)) {
    console.log("the project is not set up")
    return display_project_still_setting_up(props)
  }
  if(project_subscription_failure(props)) {
    return display_subscription_failure(props)
  }
  if(project_unsubscribe_initiated(props)) {
    return display_unsubscribed_initiated(props)
  }
  if(project_unsubscribed(props)) {
    return display_unsubscribed(props)
  }
  console.log(props.common.isLoading, props.common.isAuthed, props.common.project.selectedProject, metricsData)
  if(!props.common.isLoading && props.common.isAuthed && props.common.project.selectedProject !== null) {
    if(metricsData == null) {
      console.log("need to call the metrics api")
      retrieveMetricsData(props.common.api_token, props.common.project.selectedProject.id, props.common.oauth_id_token.__raw)
        .then((response) => {
          console.log("retrieved data", response)
          setMetricsData(response?.items)
        })
    }
  }
  return simple_homepage_data(props, loginWithRedirect, metricsData)
}

export default Home;