import { Divider, Form, Input, Select, Button, message, Checkbox, Space } from "antd";
import { useState } from "react"
import { QuestionCircleOutlined } from '@ant-design/icons';
import Period from "./Period";
import { createSchedule, updateSchedule } from "../requests";
import dayjs from 'dayjs';
import ResourcePool from "./ResourcePool";
import { get_docs_url } from "../helpers";

const fixPeriodTimes = (period) => {
  if (period.start_time) {
    var hh_s = `${period.start_time.hour()}`
    var mm_s = `${period.start_time.minute()}`
    period.start_time = `${hh_s.padStart(2, '0')}:${mm_s.padStart(2, '0')}`
  }
  if (period.end_time) {
    var hh_e = `${period.end_time.hour()}`
    var mm_e = `${period.end_time.minute()}`
    period.end_time = `${hh_e.padStart(2, '0')}:${mm_e.padStart(2, '0')}`
  }
  return period
}

const fixPeriodWeekdays = (period) => {
  if (period.weekdays && Array.isArray(period.weekdays)) {
    period.weekdays = period.weekdays.join(",")
  }
  return period
}

const processScheduleData = (newData, isUpdate, props, messageApi, form, disableButton, update_selected_schedule, timeout) => {
  var msg = "saving new schedule"
  if (isUpdate) {
    msg = "updating schedule"
  }
  messageApi.open({
    type: "loading",
    content: msg,
    duration: 0
  })
  console.log("processScheduleData", newData, isUpdate)
  newData.periods = newData.periods.map(fixPeriodTimes)
  newData.periods = newData.periods.map(fixPeriodWeekdays)
  console.log("processScheduleData data after date fix", newData)
  if (isUpdate) {
    console.log("updating an existing schedule")
    updateSchedule(newData, props.common.api_token, props.common.project.selectedProject.id, props.common.oauth_id_token.__raw)
      .then((response) => {
        clearTimeout(timeout)
        messageApi.destroy()
        if (response.status === 200) {
          messageApi.open({ type: "success", content: "successfully updated the schedule", duration: 3 })
        } else {
          const data = response.json()
          console.log("update error data", data)
          messageApi.open({ type: "error", content: data.message, duration: 3 })
        }
        disableButton(false)
        console.log("processScheduleData.update_selected_schedule", update_selected_schedule)
        if(update_selected_schedule) {
          update_selected_schedule(newData.schedule_id, newData)
        }
      })
  } else {
    console.log("creating a new schedule")
    createSchedule(newData, props.common.api_token, props.common.project.selectedProject.id, props.common.oauth_id_token.__raw)
      .then((response) => {
        clearTimeout(timeout)
        messageApi.destroy()
        if (response.status === 200) {
          messageApi.open({ type: "success", content: "successfully created the schedule", duration: 5 })
        } else {
          const data = response.json()
          console.log("update error data", data)
          messageApi.open({ type: "error", content: data.message, duration: 3 })
        }
        form.resetFields()
        disableButton(false)
      })
  }
}

const augmentPeriodTimes = (period) => {
  console.log("updating period", period)
  if (period.start_time) {
    period.start_time = dayjs(period.start_time, "HH:mm")
  }
  if (period.end_time) {
    period.end_time = dayjs(period.end_time, "HH:mm")
  }
  console.log("updated period", period)
  return period
}

const unpackPeriodWeekdays = (period) => {
  console.log("unpackPeriodWeekdays")
  if (period.weekdays && typeof(period.weekdays) == "string") {
    period.weekdays = period.weekdays.split(",")
  }
  console.log(period)
  return period
}

const Schedule = (props) => {
  console.log("Schedule.props", props)
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [scheduleType, setScheduleType] = useState(props.schedule_data.schedule_type)
  const [disableButton, setButtonDisable] = useState(false)
  if (props.schedule_data.periods) {
    props.schedule_data.periods = props.schedule_data.periods.map(augmentPeriodTimes)
    props.schedule_data.periods = props.schedule_data.periods.map(unpackPeriodWeekdays)
  }
  if (props.schedule_data.schedule_type !== undefined && props.schedule_data.schedule_type !== scheduleType) {
    setTimeout(() => setScheduleType(props.schedule_data.schedule_type), 0)
  }
  const isUpdate = !!!props.new
  // const [formData, setFormData] = useState(props.schedule_data)
  const onFinish = (values) => {
    console.log('Received values of form:', values);
    var timeout = setTimeout(() => {
      messageApi.destroy();
      messageApi.open({ type: "error", contant: "failed submitting the schedule", duration: 3 })
      setButtonDisable(false);
    }, 10000)
    processScheduleData(values, isUpdate, props, messageApi, form, setButtonDisable, props.update_selected_schedule, timeout);
    setTimeout(() => setButtonDisable(true), 0)
  };
  const changeScheduleType = (value) => {
    console.log("changeScheduleType", value)
    setScheduleType(value)
  }
  form.setFieldsValue(props.schedule_data)
  console.log("props.schedule_data", props.schedule_data)

  const EC2ScheduleDefaults = () => {
    return <></>
  }

  const ASGScheduleDefaults = () => {
    return <>
      <Form.Item style={{display: 'inline-block', marginBottom: 0}}>
        <ResourcePool top_level_name="default_resource_pool" default={true}/>
      </Form.Item>
    </>
  }

  const LambdaScheduleDefaults = () => {
    return <>
      <Divider style={{fontSize: 10}}>Default Concurrency</Divider>
      <Space size={[2,2]} wrap>
        <Form.Item
          label={<div>Reserved Concurrency <QuestionCircleOutlined onClick={() => {get_docs_url("/schedules/periods/lambda/#reserved-concurrency")}}/></div>}
          style={{margin: 2}}
          name='default_reserved_concurrency'>
          <Input/>
        </Form.Item>
        <Form.Item
          label={<div>Provisioned Concurrency <QuestionCircleOutlined onClick={() => {get_docs_url("/schedules/periods/lambda/#provisioned-concurrency")}}/></div>}
          style={{margin: 2}}
          name='default_provisioned_concurrency'>
          <Input/>
        </Form.Item>
      </Space>
    </>
  }

  const RDSScheduleDefaults = () => {
    return <>
      <Form.Item style={{margin: 2}}
        label="Ignore Maintenance Window"
        name="rds_ignore_maintenance_window"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
    </>
  }

  const typeSpecificDefaults = () => {
    if(scheduleType === "asg") {
      return ASGScheduleDefaults()
    }
    if(scheduleType === "ec2") {
      return EC2ScheduleDefaults()
    }
    if(scheduleType === "lambda") {
      return LambdaScheduleDefaults()
    }
    if(scheduleType === "rds") {
      return RDSScheduleDefaults()
    }
    return <div></div>
  }

  const ScheduleForm = () => {
    return <Form
      form={form}
      initialValues={props.schedule_data}
      style={{ height: "100%", overflow: "scroll", margin: 16}}
      onFinish={onFinish}
    >
      {contextHolder}
      <Form.Item
        label="Schedule Name"
        name="schedule_id"
        style={{margin: 2}}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Schedule Type"
        name="schedule_type"
        style={{width: '35%', margin: 2}}
      >
        <Select
          options={[{ "value": "ec2", "label": "EC2" }, { "value": "lambda", "label": "Lambda" }, { "value": "rds", "label": "RDS" }, { "value": "asg", "label": "Autoscaling Groups" }]}
          disabled={isUpdate}
          onChange={changeScheduleType}
        />
      </Form.Item>
      <Form.Item
        label="Timezone"
        name='timezone'
        style={{width: 150, margin: 2}}
      >
        <Select options={[{value: "EST", label: "EST"}, {value: "PST", label: "PST"}, {value: "UTC", label: "UTC"}]}/>
      </Form.Item>
      {typeSpecificDefaults()}
      <Divider style={{marginBottom: 0, marginTop: 0}}>Periods</Divider>
      <Form.Item>
        <Form.List
          name="periods"
        >
          {(fields, { add, remove }) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 2,
              }}>
              {fields.map((field) => {
                console.log("fields.map(field) => ", field)
                return (
                  <Period data={field} type={scheduleType} remove={remove} />
                )
              })}
              <Button type="dashed" onClick={() => add()} block>
                + Add Period
              </Button>
            </div>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={disableButton}>
          {isUpdate ? "Update" : "Submit"}
        </Button>
      </Form.Item>
    </Form>
  }
  return ScheduleForm()
}

export default Schedule;