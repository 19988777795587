import LogoutButton from "./LogoutButton";
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Space } from 'antd';

const generateItems = (data) => {
  console.log("generateItems")
  return [
    {
      key: '1',
      label: data.common.user.email,
    },
    // {
    //   key: '2',
    //   label: <a href='mailto:support@skysaver.io'>support@skysaver.io</a>,
    // },
    {
      key: '3',
      danger: true,
      label: (
        <LogoutButton />
      ),
    },
  ]
}

const Profile = (props) => {
  console.log("Profile")
  if (props.common.isLoading) {
    console.log("Profile - currently loading")
    return <div>Loading ...</div>;
  }

  if (props.common.isAuthed === false) {
    console.log("Profile - setting the login button")
    return <></>
  }

  console.log("Profile - generate items")
  var items = generateItems(props);
  console.log("Profile - returning results")
  return (
    props.common.isAuthed && (
      <Dropdown
        menu={{
          items,
        }}
      >
        <Space wrap size={16}>
          <Avatar style={{height: "10vh"}} size={64} icon={<UserOutlined style={{marginTop: "3vh"}} />} />
        </Space>
      </Dropdown>
    )
  );
}

export default Profile;